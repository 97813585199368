body {
    overflow-x: hidden;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.scharr-uppercase {
    text-transform: uppercase;
}

.scharr-marg-top-res-md {
    margin-top: 2rem;
}

.scharr-marg-top-res-lg {
    margin-top: 4rem;
}

.scharr-marg-res-lg {
    margin: 4rem;
}

.scharr-marg-10 {
    margin: 10px;
}

.scharr-marg-res-md {
    margin: 2rem;
}

.scharr-no-pad {
    padding: 0;
}

.scharr-marg-res-sm {
    margin: 1rem
}

.scharr-intro-text {
    margin-top: 50px;
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 20px;
}

.scharr-pic-max {
    max-width: max-content;
}

.scharr-card {
    background: rgb(0, 0, 0);
    color:  rgb(254, 209, 54);
}

.scharr-card:hover {
    opacity: 0.3;
}

.scharr-bg-gold-shadow {
    background: rgba(254, 209, 54, 0.5);
}

.scharr-no-marg-left {
    margin-left: 0;
}

.scharr-no-marg-rigth {
    margin-right: 0;
}

.scharr-intro-pic {
    background: url(../img/mokkachair.jpeg) no-repeat bottom center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.scharr-over-cont {
  width: 80%;
  height: 300px;
  border-radius: 21px 21px 0 0;
}

.scharr-over-schrein-pic {
    background: url(../img/referenzen/innen/2_innen_20191110.jpg) no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.scharr-over-ang-pic {
  background: url(../img/referenzen/innen/innen16.jpeg) no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.scharr-over-ref-pic {
  background: url(../img/referenzen/referenzen.jpeg) no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.scharr-over-team-pic {
  background: url(../img/referenzen/spez/9_spezialprojekte_20191110-min.JPG) no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.scharr-cont-black {
    background-color: black;
    color: rgb(254, 209, 54);
}

.scharr-cont-yellow {
  background-color: rgb(254, 209, 54);
  color: black;
}

.scharr-text-centered {
    text-align: center;
}

.scharr-intro-shadow {
    background: rgba(0, 0, 0, 0.5);
}

.scharr-intro-content {
    z-index: 1;
    /*padding: 40px 0;*/
	height: 100px;
}

.scharr-intro-content::before {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
}

@media (min-width: 768px) {
    .scharr-intro-text {
        font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: italic;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px
    }
}


@media (min-width: 1200px) {
    .scharr-intro-content {
		height: 30vh;
	}
}

.scharr-header {
    background: url(../img/parkett.jpeg) no-repeat bottom center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    text-align: center;
    color: white;

}

@media (min-width: 1201px) {
    .scharr-intro-content {
		height: 25vh;
	}
}

@media (min-width: 1800px) {
	.scharr-intro-content {
		padding: 100px 0 80px;
		margin-left: -40px;
		min-height: 100px;
	}
}

.scharr-section {
    padding: 40px 0 0 0;
}

@media (min-width: 768px) {
    .scharr-section {
        padding: 50px 0 0 0;
    }
}

.showcase .showcase-text{
    padding:3rem
}
.showcase .showcase-img{
    min-height:30rem;background-size:cover
}
@media (min-width:768px){
    .showcase .showcase-text{
        padding:7rem
    }
}

.mb-0 {
    margin-bottom: 0!important;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.p-0 {
    padding: 0!important;
}

.scharr-kontakt {
  border: 1px solid #42dca3;
  color: #42dca3;
  background-color: transparent;
}

.scharr-kontakt:hover,
.ischarr-kontakt:focus {
  border: 1px solid #42dca3;
  outline: 0;
  color: #000;
  background-color: #42dca3;
}
.scharr-kontakt-yellow {
  border: 1px solid rgb(254, 209, 54);;
  color: rgb(254, 209, 54);;
  background-color: transparent;
}

.scharr-kontakt-yellow:hover,
.ischarr-kontakt-yellow:focus {
  border: 1px solid rgb(254, 209, 54);;
  outline: 0;
  color: #000;
  background-color: rgb(254, 209, 54);;
}

.scharr-hover:hover, .scharr-hover:focus{
  opacity: 0.3;
}

ul.banner-social-buttons {
  margin-top: 0;
}

@media(max-width:1199px) {
  ul.banner-social-buttons {
    margin-top: 15px;
  }
}

@media(max-width:767px) {
  ul.banner-social-buttons li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }

  ul.banner-social-buttons li:last-child {
    margin-bottom: 0;
  }
}

.scharr-card-gall {
  background-color: rgb(254, 209, 54);
  padding: 1rem;
  box-shadow: 10px 10px black;
}

.scharr-text-yellow {
  color: rgb(254, 209, 54);
}

